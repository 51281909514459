/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import React from 'react';
import { ModalProvider } from './src/context/modalContext';
import { SignUpProvider } from './src/context/signupContext';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import Cookies from 'js-cookie';

import './src/fonts/fonts.css';
// import './src/pricing-calculator/assets/js/main.ts';

export const wrapRootElement = ({ element }) => {
  return (
    <ModalProvider>
      <SignUpProvider>{element}</SignUpProvider>
    </ModalProvider>
  );
};

export const onClientEntry = () => {
  // Set the default consent values to "denied" ONLY if the user has not provided consent
  const analyticsConsent = Cookies.get('gatsby-gdpr-google-analytics');
  const adStorageConsent = Cookies.get('gatsby-gdpr-google-tagmanager');

  // If consent cookies do not exist, set default consent state to "denied"
  if (!analyticsConsent && !adStorageConsent) {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
      wait_for_update: 500,
    });
    // console.log('Current dataLayer state: ', window.dataLayer);
    // console.log('Changed default consent to denied');
  }
};

export const onRouteUpdate = ({ location }) => {
  // Remove or modify this condition for local testing
  if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') {
    const analyticsConsent = Cookies.get('gatsby-gdpr-google-analytics');
    console.log('onRouteUpdate: analyticsConsent =', analyticsConsent);

    if (analyticsConsent === 'true') {
      initializeAndTrack(location);
      // console.log('Initialize and Track - Consent Granted');
    } else {
      console.log('No consent given - Tracking not initialized');
    }
  }
};
