// SignUpContext.js
import React, { createContext, useContext, useState } from 'react';

const SignUpContext = createContext();

export const useSignUpContext = () => {
  const context = useContext(SignUpContext);

  if (context === undefined) {
    // Instead of throwing, return a default context
    return { signUp: false, toggleSignUp: () => {} };
  }
  return context;
};

export const SignUpProvider = ({ children }) => {
  const [signUp, setSignUp] = useState(false);

  const toggleSignUp = () => {
    setSignUp(!signUp);
  };

  return (
    <SignUpContext.Provider value={{ signUp, toggleSignUp, setSignUp }}>
      {children}
    </SignUpContext.Provider>
  );
};
